const container = document.getElementById('lottie-earth');

if (!container) {
  console.error('Animation container not found');
  return;
}

const ANIMATION_DESKTOP = 'desktop';
const ANIMATION_MOBILE = 'mobile';
const BREAKPOINT = 1100;

let currentAnimationType = null; // Can be null, "desktop", or "mobile"
let hasObserver = false;

async function loadAnimation() {
  const nextAnimationType = window.innerWidth < BREAKPOINT ? ANIMATION_MOBILE : ANIMATION_DESKTOP;

  if (currentAnimationType === nextAnimationType) {
    return;
  }

  try {
    const lottie = await import('lottie-web');

    let animationData = null;

    if (nextAnimationType === ANIMATION_MOBILE) {
      animationData = await import('../animations/earth-mobile.json');
    }
    if (nextAnimationType === ANIMATION_DESKTOP) {
      animationData = await import('../animations/earth-desktop.json');
    }

    if (animationData === null) {
      console.error('Animation data not loaded');
      return;
    }

    container.innerHTML = '';
    currentAnimationType = null;

    const animation = lottie.loadAnimation({
      container,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData
    });
    animation.addEventListener('DOMLoaded', function() {
      currentAnimationType = nextAnimationType;
    });
  } catch (error) {
    console.error('Error loading animation data:', error);
  }
}

function observeAnimation() {
  const observer = new IntersectionObserver((entries) => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        loadAnimation();
        observer.unobserve(entry.target);
        hasObserver = false;
      }
    });
  }, {
    rootMargin: '500px'
  });

  const target = document.getElementById('lottie-earth');
  if (target) {
    observer.observe(target);
    hasObserver = true;
  }
}

observeAnimation();

window.addEventListener('resize', () => {
  if (hasObserver) {
    return;
  }

  const nextAnimationType = window.innerWidth < BREAKPOINT ? ANIMATION_MOBILE : ANIMATION_DESKTOP;

  if (currentAnimationType === nextAnimationType) {
    return;
  }

  observeAnimation();
})
